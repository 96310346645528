'use strict';
/**
 * content-Default-Values:
 * - visuType: Controls.VisuType.CELL_ONLY, --> cell, expandable, control_content
 * - useBaseHeader: true,                   --> set to NO if a customized cell is to be used
 * - [showControlNameAsSubtitle: false]     --> only used if useBaseHeader is true
 *
 *
 * delegate
 *      didSelectControl --> only as information, will still proceed with didSelectCell afterwards.
 */

window.Controls = window.Controls || {};

class ControlBase extends GUI.TableViewV2.Cells.BaseCell {
    //region Static
    static Template = function () {
        var generateBaseHeader = function generateBaseHeader(name, showControlNameAsSubtitle, groupDetail, visuType, showStatistic) {
            var extraHeaderClass = "",
                controlNameAsSubtitle = "";

            if (showControlNameAsSubtitle) {
                extraHeaderClass = " container--with-subtitle";
                controlNameAsSubtitle = '<span class="group-details__control-name">' + name + '</span>';
                groupDetail = SEPARATOR_SYMBOL + groupDetail;
            }

            var disclosureIcon = ImageBox.getResourceImageWithClasses('resources/Images/General/disclosure-icon.svg', 'title__disclosure-icon');
            return $('<div class="content__label-container' + extraHeaderClass + '">' + '   <div class="label-container__title">' + '       <div class="title__name-label text-overflow-ellipsis">' + name + '</div>' + '       ' + disclosureIcon + '   </div>' + '   <div class="label-container__group-details text-overflow-ellipsis">' + '       ' + controlNameAsSubtitle + '       <span class="group-details__favorites">' + groupDetail + '</span>' + '   </div>' + '</div>' + '<div class="content__control-area" />');
        };

        return {
            generateBaseHeader: generateBaseHeader
        };
    }(); //endregion Static

    //region Getter
    get delegate() {
        return this;
    }

    get dataSource() {
        return this;
    } //endregion Getter


    //region Setter
    set delegate(delegate) {
        return super.delegate = delegate;
    }

    set dataSource(dataSource) {
        super.dataSource = dataSource;
    } //endregion Setter


    constructor(delegate, dataSource, control, sectionIdx, rowIdx, tableView) {
        super(null, null, control.controlType, sectionIdx, rowIdx, tableView);
        Object.assign(this, StateHandler.Mixin);
        this.element.addClass("control-base-cell"); // the control cell uses itself as delegate and data source, still, store the original del so it can be informed.

        this.originalDelegate = delegate;
        this.control = control;
        this.showGroupDetails = this.detectShowGroupDetails(dataSource); // Don't use this.dataSource as it's modified.

        this.showStatistic = !!this.control.statistic;
        this._expertModeEnabled = ActiveMSComponent.isExpertModeEnabled() && (!this.control.uuidParent || !!this.control.parentControl);
    }

    viewDidLoad() {
        return super.viewDidLoad(...arguments).then(() => {
            this.element.addClass(convertControlTypeToClassNameWithSuffix(this.control.controlType, "control-cell")); // header
            this.headerElements = {};
            return Q(this.initHeader()).then(headerContent => {
                let prms = [];
                if (this.content.useBaseHeader) {
                    var showControlNameInSubtitle = !!this.content.showControlNameAsSubtitle && this.nrOfEqualCtrls() > 1;
                    this.header = ControlBase.Template.generateBaseHeader(this.control.name, showControlNameInSubtitle, this.control.groupDetail, this.content.visuType, this.showStatistic);
                    this.headerElements.controlCellTitle = this.header.find('.title__name-label');
                    this.headerElements.disclosureIcon = this.header.find('.title__disclosure-icon'); // call initHeader to get the children for the control area

                    this.headerElements.controlArea = this.header.closest('.content__control-area');
                    this.headerElements.controlArea.append(headerContent);
                } else {
                    // call initHeader to get the complete header
                    this.header = headerContent;
                }

                this.contentPlaceholder.append(this.header); // detail

                this.detailElements = {};

                if (this.control.presence) {
                    this.presenceSimulationBar = new GUI.LxPresenceSimulationBar();
                    this.headerElements.presenceSimulationBar = this.presenceSimulationBar.getElement().hide();
                    prms.push(this.appendSubview(this.presenceSimulationBar));
                }

                this._updateDiscIcon();

                if (this.updateHeader || this.updateDetail) {
                    Controls.ControlDisabler.call(this); // call this before registering!
                }

                if (this.showGroupDetails) {
                    this.element.addClass("control-base-cell--with-details");
                }

                return Q.all(prms);
            });
        });
    }

    initDetail(detailElement) {
        if (this.showStatistic || this._expertModeEnabled) {
            var row = 0,
                detailCellPlaceholder = $('<div class="detail__table-view-cells"><div class="table-view-cells__border"></div></div>');
            detailElement.append(detailCellPlaceholder);

            if (this.showStatistic) {
                this.statisticCell = new GUI.TableViewV2.Cells.GeneralCell(this, this, GUI.TableViewV2.CellType.GENERAL, 0, row);
                this.appendSubview(this.statisticCell, detailCellPlaceholder);
                row++;
            }

            if (this._expertModeEnabled) {
                this.expertModeCell = new GUI.TableViewV2.Cells.GeneralCell(this, this, GUI.TableViewV2.CellType.GENERAL, 0, row);
                this.appendSubview(this.expertModeCell, detailCellPlaceholder);
            }
        }
    }

    viewWillAppear() {
        var baseCall = super.viewWillAppear(...arguments);

        this._updateDiscIcon();

        if (this.updateHeader || this.updateDetail) {
            this._requestStates();
        }

        return baseCall;
    }

    viewDidAppear() {
        var baseCall = super.viewDidAppear(...arguments);

        if (this.updateHeader || this.updateDetail) {
            this._registerForStates();
        }

        return baseCall;
    }

    getStateIDs() {
        return null;
    }

    viewWillDisappear(viewRemainsVisible) {
        // status updates
        if (this.updateHeader || this.updateDetail) {
            this._unregisterStates();
        }

        return super.viewWillDisappear(...arguments);
    }

    getReuseID() {
        return this.control.uuidAction;
    }

    destroyDetail() {
        if (this.statisticCell) {
            this.removeSubview(this.statisticCell);
            this.statisticCell = null;
        }

        if (this.expertModeCell) {
            this.removeSubview(this.expertModeCell);
            this.expertModeCell = null;
        }
    }

    contentForCell(cell, section, row, tableView) {
        if (!tableView) {
            if (cell === this.statisticCell) {
                return {
                    leftIconSrc: "resources/Images/Controls/Statistic/chart.svg",
                    title: _("statistics"),
                    disclosureIcon: true
                };
            } else if (cell === this.expertModeCell) {
                return {
                    leftIconSrc: "resources/Images/General/icon-expert-mode.svg",
                    leftIconColor: window.Styles.colors.red,
                    title: _("expert-settings"),
                    disclosureIcon: true
                };
            }
        } else {
            return {
                visuType: Controls.VisuType.CELL_ONLY,
                useBaseHeader: true
            };
        }
    }

    /**
     * return the elements you want to be replaced with an indicator while no state is available yet.
     * 1) to replace the whole "control area", simply don't implement this method - the base class handles this.
     * 2) to replace nothing, return an empty object.
     * 3) to replace one or more child elements in the "control area", return { single: [ {jQuery Element},.. ] }
     * 4) to replace one or more child elements (right aligned) in the "control area", return { singleRight: [ {jQuery Element},.. ] }
     * @returns {*}
     */
    getStatusElements() {
        if (this.content.useBaseHeader) {
            return {
                full: [this.headerElements.controlArea]
            };
        } else {
            return null;
        }
    }

    /** The control disabler needs "setHasAllStatesReceived", but the StateHandler mixin registers for "allStatesReceived" **/
    allStatesReceived() {
        this.setHasAllStatesReceived.apply(this, arguments);
    }

    /** The StateHandler mixin registers for "receivedStates", but the controlCell works with _onStateChange **/
    receivedStates() {
        this._onStateChange.apply(this, arguments);
    }

    clickableAllowed() {
        return this.expandableAllowed() || this.content.visuType === Controls.VisuType.CONTROL_CONTENT || this.showStatistic || this._expertModeEnabled;
    }

    expandableAllowed() {
        return this.content.visuType === Controls.VisuType.EXPANDABLE || this.isExpanded;
    }

    didSelectCell(cell, section, row) {
        if (this.disabler && !this.disabler.allStatesReceived) {
            return;
        }

        if (this.originalDelegate.didSelectControl) {
            this.originalDelegate.didSelectControl.call(this.originalDelegate, cell, section, row, this.tableView, this.control);
        }

        if (cell === this.statisticCell) {
            NavigationComp.showStatistic(this.control.uuidAction, this.control.statistic.outputs[0].uuid);
        } else if (cell === this.expertModeCell) {
            NavigationComp.showControlSettings(this.control);
        } else {
            this.showControlContent();
        }
    }

    expandStateChanged(expandState) {
        super.expandStateChanged(expandState);

        if (this.disabler && !this.disabler.allStatesReceived) {
            return;
        }

        if (expandState === ExpandState.Expanding) {
            this.updateHeader && this.updateHeader(this.states);
            this.updateDetail && this.updateDetail(this.states);
        } else if (expandState === ExpandState.Collapsing) {
            this.updateHeader && this.updateHeader(this.states);
        }
    }

    // Public methods

    /**
     * @see Control.sendCommand
     */
    sendCommand(cmd, type, cUuidAction, dr, argTexts) {
        var cmdSrc = CmdSrc.CELL,
            promise; // it's important to know if a control is cell only, because these will never send commands from the content.

        if (this.content.visuType === Controls.VisuType.CELL_ONLY) {
            cmdSrc = CmdSrc.CELL_ONLY;
        }

        promise = Q(this.control.sendCommand.call(this.control, cmd, type, cUuidAction, dr, argTexts, cmdSrc));
        promise.then(function success() {
            //console.log("ControlBase sendCommand successful")
            this.setCommandsInQueue && this.setCommandsInQueue(false);
        }.bind(this), function error(e) {
            console.error(e);
            this.states && this._onStateChange(this.states); // check for this.states, maybe we sent the command before states has been received..

            this.setCommandsInQueue && this.setCommandsInQueue(false);
        }.bind(this), function notify(i) {
            console.info(i);

            if (i === Commands.SendState.QUEUED) {
                this.setCommandsInQueue && this.setCommandsInQueue(true);
            }
        }.bind(this));
        return promise;
    }

    setExpandableAllowed(allowed) {
        this.element.toggleClass("expandable-cell", allowed);
        this.content.visuType = allowed ? Controls.VisuType.EXPANDABLE : Controls.VisuType.CELL_ONLY;

        this._updateDiscIcon();
    }

    setControlContentAllowed(allowed) {
        this.content.visuType = allowed ? Controls.VisuType.CONTROL_CONTENT : Controls.VisuType.CELL_ONLY;

        this._updateDiscIcon();
    }

    _updateDiscIcon() {
        var clickable = this.clickableAllowed();
        this.headerElements.disclosureIcon.toggle(clickable);
        this.contentPlaceholder.toggleClass("clickable", clickable);
    }

    /**
     * Shows a custom control content, if one wants to bypass controlContent and head to a specific controlContent.
     * @param [contentIdentifier] - if one doesn't want to start with xyzControlContent, but xyzControlContentCustom
     * @param [contentDetails] - arguments to pass into the controlContentScreen.
     * @param [screenState] - optional screenState to be shown directly, otherwise ControlContent!
     */
    showControlContent(contentIdentifier, contentDetails, screenState) {
        if (this.content.visuType === Controls.VisuType.CONTROL_CONTENT) {
            if (this.ViewController instanceof GUI.ControlContentViewController || this.ViewController instanceof GUI.ModalViewController) {
                // if we are in a ControlContentVC or in a ModalVC, don't use an extra ControlContentViewController
                // just show the actual ControlContent
                if (screenState) {
                    contentIdentifier = screenState;
                } else if (!contentIdentifier) {
                    contentIdentifier = this.control.getControlContentIdentifier();
                }

                if (!contentDetails) {
                    contentDetails = {};
                }

                contentDetails.control = this.control;
                this.ViewController.showState(contentIdentifier, null, contentDetails);
            } else {
                if (!screenState) {
                    screenState = this.control.getControlContentViewControllerIdentifier();
                }

                var details = {
                    controlUUID: this.control.uuidAction
                };

                if (contentIdentifier) {
                    details.contentIdentifier = contentIdentifier;
                }

                if (contentDetails) {
                    details.contentDetails = contentDetails;
                }

                NavigationComp.showState(screenState, details);
            }
        } else if (this.showStatistic && this._expertModeEnabled || this._expertModeEnabled) {
            NavigationComp.showState(ScreenState.ControlAdditionals, {
                control: this.control
            });
        } else if (this.showStatistic) {
            NavigationComp.showStatistic(this.control.uuidAction, this.control.statistic.outputs[0].uuid);
        }
    }

    nrOfEqualCtrls() {
        var res = 2; // if the control is shown in a regular tableView (search), assume there are more equal controls.

        if (this.tableView.numberOfEqualControlsInSection) {
            res = this.tableView.numberOfEqualControlsInSection(this.cellType, this.sectionIdx, this.control.room, this.control.cat);
        }

        return res;
    }

    /**
     * Detects whether or not the controls groupDetails have to be shown.
     * @param dataSource    the detection is based on the dataSource
     * @returns {boolean}
     */
    detectShowGroupDetails(dataSource) {
        var showGroupDetails = false;
        showGroupDetails |= dataSource.name === "FavoritesScreen";
        showGroupDetails |= dataSource.name === "FavoritesScreenHD";
        showGroupDetails |= dataSource.name === "SearchScreen";
        return showGroupDetails;
    }

    // Private methods
    _onStateChange(states) {
        this.states = states;
        this.updateHeader && this.updateHeader(states);

        if (this.control.presence && states.presenceSimulation) {
            this.headerElements.presenceSimulationBar.toggle(states.presenceSimulation.active);
            this.presenceSimulationBar.setPresenceTime(states.presenceSimulation.from, states.presenceSimulation.to);
        }

        this.updateDetail && this.isExpanded && this.updateDetail(states);
    }

}

Controls.ControlBase = ControlBase;
Controls.VisuType = {
    CELL_ONLY: "cell",
    EXPANDABLE: "expandable",
    CONTROL_CONTENT: "content"
};
